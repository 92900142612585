import React, { useState } from "react"

export const About = () => {

  const [showEmail, setShowEmail] = useState(false)

  return (
    <>
      <div className="jumbotron jumbotron-fluid vh-100 p-0 m-0">
        <div className="container pt-4">
          <div className="row">
            <div className="col-12">
              <h1 className="display-4">jrey.ru</h1>
              <p className="lead">
                Yuliya Ykrainets, UX Designer
              </p>
            </div>
          </div>
          <hr className="my-4"/>
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-6 col-12 d-inline-flex flex-column">
              <a
                className="btn btn-success btn-lg m-1"
                href="https://www.behance.net/yuliyarey"
                role="button"
              >
                Behance
              </a>
              { showEmail ? (
                <a
                  href="mailto:me@jrey.ru"
                  className="btn btn-success btn-lg m-1"
                >
                  me@jrey.ru
                </a>
              ) : (
                <button
                  onClick={() => setShowEmail(true)}
                  className="btn btn-secondary btn-lg m-1"
                >
                  Email
                </button>
              )}
              <a
                className="btn btn-info btn-lg m-1"
                href="tg://resolve?domain=yuliyarey"
                role="button"
              >
                Telegram
              </a>

              <a
                className="btn btn-dark btn-lg m-1"
                href="tg://resolve?domain=danakilfreelance"
                role="button"
              >
                Telegram channel
              </a>

              <a
                className="btn btn-light btn-lg m-1"
                href="https://figma.fekcl.com/"
                role="button"
              >
                Уроки по Figma
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
